export default {
    "Eerie County": { "x": 10991, "y": 16000 },
    "Raven Creek": { "x": 3936, "y": 11798 },
    "Riverside": { "x": 6424, "y": 5285 },
    "Muldraugh": { "x": 10668, "y": 9795 },
    "West Point": { "x": 11671, "y": 6836 },
    "Lake Ivy": { "x": 9299, "y": 9790 },
    "Rosewood": { "x": 8247, "y": 11206 },
    "Louisville": { "x": 13078, "y": 2477 },
    "Big Bear Lake": { "x": 5700, "y": 7500 },
    "Lake Cumberland": { "x": 16875, "y": 6851 }, 
    "Dirkerdam": { "x": 7651, "y": 3303 }, 
    "Taylorsville": { "x": 9675, "y": 6873 }, 
    "Fort Redstone": { "x": 5616, "y": 11940 }, 
    "Oakdale University": { "x": 12439, "y": 11660 }, 
    "Petroville": { "x": 11002, "y": 12185 }, 
    "March Ridge": { "x": 10089, "y": 12765 }, 
    "Chesttown": { "x": 4667, "y": 6725 }, 
    "LV Port": { "x": 12428, "y": 4673 }, 
    "LV International": { "x": 13009, "y": 4650 }, 
    "Wilbore": { "x": 4823, "y": 10106 }, 
    "Grapeseed": { "x": 7325, "y": 11212 }, 
    "Ekron": { "x": 7276, "y": 8324 }, 
    "Nettle Township": { "x": 6915, "y": 9274},
    "Pineville": { "x": 4128, "y": 9704},
    "Cathaya Valley": { "x": 4066, "y": 8929},
    "Elroy": { "x": 3756, "y": 8185}
  }