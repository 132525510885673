import OpenSeadragon from "openseadragon";

OpenSeadragon.Drawer.clipWithPolygons = function (polygons, useSketch) {
    if (!this.useCanvas) {
        return;
    }
    var context = this._getContext(useSketch);
    polygons.forEach(function (polygon) {
      context.beginPath();
        polygon.forEach(function (coord, i) {
            context[i === 0 ? 'moveTo' : 'lineTo'](coord.x, coord.y);
      });
      context.clip();
    });
}

OpenSeadragon.TiledImage.setCroppingPolygons = function( polygons ) {

    var isXYObject = function(obj) {
        return obj instanceof OpenSeadragon.Point || (typeof obj.x === 'number' && typeof obj.y === 'number');
    };

    var objectToSimpleXYObject = function(objs) {
        return objs.map(function(obj) {
            try {
                if (isXYObject(obj)) {
                    return { x: obj.x, y: obj.y };
                } else {
                    throw new Error();
                }
            } catch(e) {
                throw new Error('A Provided cropping polygon point is not supported');
            }
        });
    }; 

    try {
        if (!OpenSeadragon.isArray(polygons)) {
            throw new Error('Provided cropping polygon is not an array');
        }
        this._croppingPolygons = polygons.map(function(polygon){
            return objectToSimpleXYObject(polygon);
        });
    } catch (e) {
        OpenSeadragon.console.error('[TiledImage.setCroppingPolygons] Cropping polygon format not supported');
        OpenSeadragon.console.error(e);
        this._croppingPolygons = null;
    }
}

OpenSeadragon.console = {
    log: function(message) {
        if (typeof message === 'string' && message.includes('Tile %s failed')) {
            // Suppressing, these errors  are expected and created a lot of noise.
            return;
        }
        console.log.apply(console, arguments);  // Let other logs through
    },
    error: console.error,
    warn: console.warn,
    assert: OpenSeadragon.console.assert
};

export default OpenSeadragon;