import { icon } from '@fortawesome/fontawesome-svg-core';

class IconConverterService {
    static convertIcon(faIcon, color) {
        const img = new Image();

        const buildingIcon = icon(faIcon);

        if (buildingIcon) {
            let svgHtml = buildingIcon.html.join('');

            svgHtml = svgHtml.replace(/fill="(none|currentColor)"/g, '');

            svgHtml = svgHtml.replace('<svg', `<svg style="fill: ${color};"`);
            svgHtml = svgHtml.replace(/<path/g, `<path style="fill: ${color};"`);

            const svgDataUrl = 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(svgHtml);

            img.src = svgDataUrl;
            return img;
        }
    }
}

export default IconConverterService;