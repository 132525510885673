export default {
    "CC": { "x": 11072, "y": 8851 },
    "Raven Creek": { "x": 5210, "y": 10645 },
    "Riverside": { "x": 5964, "y": 5276 },
    "West Point East": { "x": 12074, "y": 7235 },
    "Lake Ivy": { "x": 8773, "y": 9760 },
    "Rosewood": { "x": 8819, "y": 11897 },
    "Louisville": { "x": 13240, "y": 3508 },
    "Eerie Country": { "x": 10457, "y": 17218 },
    "Big Bear Lake": { "x": 7015, "y": 7574 },
    "LC": { "x": 15444, "y": 6521 }, 
  }