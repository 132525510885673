import React from 'react';
import { useState } from 'react';

import {
    convert_to_human_date,
    compare_created_at,
    compare_names,
    compare_player,
    compare_achievement
} from "../services/Util";

export default function Achievements({ players, achievements }) {

    const [sortedField, setSortedField] = useState("timeline");
    const [playerNameFilter, setPlayerNameFilter] = useState("");

    const [display1000kills, setDisplay1000kills] = useState('false' === localStorage.getItem("display_1000_kills"));

    if (sortedField === "timeline") achievements.sort(compare_created_at);
    if (sortedField === "player") achievements.sort(compare_player);
    if (sortedField === "achievement") achievements.sort(compare_achievement);

    const handleDisplay1000Checkbox = () => {
        localStorage.setItem("display_1000_kills", !display1000kills);
        setDisplay1000kills(!display1000kills);
    }

    const filterPlayer = (player_name) => playerNameFilter.length < 2 ? true : player_name.toLowerCase().search(playerNameFilter) >= 0;

    const filterCommonAchievements = (achievement) => display1000kills || achievement.achievement_key !== "killed_1_k";

    const handleFilterChange = (event)  => {
        let value = event.target.value;
        setPlayerNameFilter(value.length < 2 ? "" : value.toLowerCase());
    }

    let processed_achievements = [];
    let last_player = "";
    let last_date = "";

    processed_achievements = achievements
        .filter(achievement => (filterPlayer(achievement.player)))
        .filter(achievement => (filterCommonAchievements(achievement)))
        .map(achievement => {
            let processed_name = "";
            if (achievement.player !== last_player) {
                processed_name = achievement.player;
                last_player = processed_name
            }

            let processed_date = "";
            let achievement_date = convert_to_human_date(achievement.created_at);
            if (achievement_date !== last_date) {
                processed_date = achievement_date;
                last_date = achievement_date;
            }

            return {
                id: achievement.id,
                name: processed_name,
                date: processed_date,
                image: "achievements/achiev-" + achievement.achievement_key + ".png"
            };
        });

    players = players
        .filter(player => (filterPlayer(player.name)))
        .sort(compare_names);

    return (
        <div className="columns is-centered mt-5">

            <div style={{ marginLeft: 18 }} className="column is-narrow is-hidden-mobile is-hidden-tablet-only">
                <div>
                    <div className="panel-block">
                        <div className="is-inline-block" >
                            <p style={{ color: "#be9644" }} >Filter:</p>
                            <input style={{ height: '1.5em' }} className="input" type="text" placeholder="Search" onChange={handleFilterChange} />
                        </div>
                    </div>
                    <div className="panel-block">
                        <table className="table">
                            <tbody>
                                {
                                    players.map(player =>
                                        <tr className="no-divider" key={player.name}>
                                            <td style={{ paddingTop: 0, paddingBottom: 0 }} >{player.name}</td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div className="column is-5">
                <table className="table add-buffer is-narrow is-fullwidth">
                    <thead>
                        <tr>
                            <th className="is-clickable" onClick={() => setSortedField("player")}>Player</th>
                            <th className="is-clickable" onClick={() => setSortedField("timeline")}>Date</th>
                            <th className="is-clickable" onClick={() => setSortedField("achievement")}>Achievements</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr className="no-divider" style={{ outline: 'thin solid transparent', height: '1em' }}>
                            <td style={{ fontSize: 0 }}>.</td></tr>

                        {
                            processed_achievements.map(processed_achievement =>
                                <tr className="no-divider" key={processed_achievement.id}>
                                    <td>{processed_achievement.name}</td>
                                    <td>{processed_achievement.date}</td>
                                    <td> <img alt='' src={processed_achievement.image} width="300" /></td>
                                </tr>
                            )
                        }
                    </tbody>
                </table>
            </div>
            <div className="column is-2">
                <h1>Achievements</h1>

                <img alt='' src="menu/menu_kills.png" />
                <img alt='' src="menu/menu_winter.png" />
                <img alt='' src="menu/menu_collections.png" />
                <img alt='' src="menu/menu_rescuing.png" />
                <img alt='' src="menu/menu_explorer.png" />
                <img className="disabled" alt='' src="menu/menu_hardcore.png" />
                <img className="disabled" alt='' src="menu/menu_mentor.png" />
                <img className="disabled" alt='' src="menu/menu_vehicles.png" />
                <img className="disabled" alt='' src="menu/menu_crafting.png" />
                <img className="disabled" alt='' src="menu/menu_food.png" />


                <div style={{ backgroundColor: 'inherit' }} className="panel-block">
                    <div className="block">
                        <label className="checkbox pr-1">
                            <input type="checkbox" checked={display1000kills} onChange={handleDisplay1000Checkbox} />
                        </label>
                        Display Common Achievements
                    </div>
                </div>
            </div>

        </div >
    )
}
