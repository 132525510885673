import React from 'react';
import {NavLink} from "react-router-dom";

export default function Navigation() {

    const [isBurgerActive, setIsBurgerActive] = React.useState(false);

    function close_burger() {
        setIsBurgerActive(false);
    }

    const getNavItemClass = ({ isActive }) =>  isActive ? "navbar-item is-active" : "navbar-item";

    return (
        <div >
            <nav className="navbar is-fixed-top is-black" role="navigation" aria-label="main navigation">
                <div className="navbar-brand">
                    <NavLink to='/' className="navbar-item">
                        <img alt='' src="logo-white.png" />
                    </NavLink>

                    <a href='/#' role="button" onClick={() => {
                        setIsBurgerActive(!isBurgerActive);
                    }}
                       className="navbar-burger"
                       aria-label="menu"
                       aria-expanded="false"
                       data-target="navbarBasicExample">
                        <span aria-hidden="true"></span>
                        <span aria-hidden="true"></span>
                        <span aria-hidden="true"></span>
                    </a>
                </div>

                <div id="navbarBasicExample" className={`navbar-menu ${isBurgerActive ? 'is-active' : ''}`}>
                    <div className="navbar-start">
                        <NavLink to='/' className={getNavItemClass} onClick={close_burger}> Players </NavLink>

                        <NavLink to='/achievements' className={getNavItemClass} onClick={close_burger}> Achievements </NavLink>

                        <NavLink to='/economy' className={getNavItemClass} onClick={close_burger}> Economy </NavLink>

                        <NavLink to='/world-map' className={getNavItemClass} onClick={close_burger}> World Map </NavLink>
                    </div>

                </div>
            </nav >

        </div >
    )
}